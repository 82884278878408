import { userAxios } from '../../index'

const laboratoryList = async (paginate) => {
    try {
        return await userAxios.get(`laboratories/all/${paginate}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryCreate = async (data) => {
    try {
        return await userAxios.post('laboratories', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryUpdate = async (id, data) => {
    try {
        return await userAxios.put(`laboratories/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryDelete = async (id) => {
    try {
        return await userAxios.delete(`laboratories/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`laboratories/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`laboratories/search/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`laboratories/search/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    laboratoryList,
    laboratoryCreate,
    laboratoryUpdate,
    laboratoryDelete,
    laboratoryPagination,
    laboratoryFilter,
    laboratoryFilterPagination
}