<template>
    <b-sidebar id="add-new-laboratory-category-sidebar" :visible="isAddNewLaboratoryCategorySidebarActive"
        bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-laboratory-category-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="
                      d-flex
                      justify-content-between
                      align-items-center
                      content-sidebar-header
                      px-2
                      py-1
                    ">
                <h5 class="mb-0">{{ $t("Add") }} {{ $t('New') }} Type Laboratory Category</h5>

                <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <!-- Full Name -->
                    <validation-provider #default="validationContext" name="name" rules="required">
                        <b-form-group :label="$t('Form.Name')" label-for="name">
                            <b-form-input id="name" v-model="stateData.name" autofocus
                                :state="getValidationState(validationContext)" trim
                                placeholder="Type LaboratoryCategory name" />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <!-- status -->
                    <validation-provider #default="validationContext" name="laboratories" rules="required">
                        <b-form-group label="Laboratories" label-for="laboratories">
                            <treeselect id="laboratories" v-model="stateData.laboratories_id" multiple :async="true"
                                :load-options="searchLabs" :normalizer="normalizer" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="price" rules="required">
                        <b-form-group label="price" label-for="price">
                            <b-form-input type="number" id="price" v-model="stateData.price"
                                :state="getValidationState(validationContext)" placeholder="Type a price" trim />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="Status" rules="required">
                        <b-form-group :label="$t('Form.Status')" label-for="status">
                            <treeselect id="status" v-model="stateData.status"
                                :state="getValidationState(validationContext)" :options="optionsStatus"
                                :normalizer="normalizer" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
                            <span v-if="!loading">{{ $t("Add") }}</span>
                            <span v-else>
                                <SpinnerLoading />
                            </span>
                        </b-button>
                        <b-button type="button" variant="outline-danger" @click="hide">
                            {{ $t("Cancel") }}
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
} from "bootstrap-vue";
import formValidation from "@/core/utils/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import { required } from "@validations";
import { ref } from "@vue/composition-api";

import axiosCT from "@/core/services/api/admin/consults/laboratoryCategories";
import axiosLB from "@/core/services/api/admin/consults/laboratories";
import Swal from "sweetalert2";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        SpinnerLoading,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    model: {
        prop: "isAddNewLaboratoryCategorySidebarActive",
        event: "update:is-add-new-laboratory-category-sidebar-active",
    },
    props: {
        isAddNewLaboratoryCategorySidebarActive: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            required,
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
        };
    },
    setup(props, { emit }) {
        const optionsStatus = [
            { name: "Active", id: true },
            { name: "Inactive", id: false },
        ];
        const stateDataTem = {
            name: "",
            status: true,
            laboratories_id: null,
            price: 0
        };
        const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

        const searchLabs = ({ action, searchQuery, callback }) => {
            if (action === "ASYNC_SEARCH") {
                const datos = {
                    name: searchQuery,
                };
                axiosLB.laboratoryFilter(10, datos).then(({ registro: { data } }) => {
                    callback(null, data);
                });
            }
        }
        const resetuserData = () => {
            stateData.value = JSON.parse(JSON.stringify(stateDataTem));
        };
        const { refFormObserver, getValidationState, resetForm } =
            formValidation(resetuserData);

        const loading = ref(false);
        const onSubmit = () => {
            loading.value = true;
            if (stateData.value.price <= 0) {
                loading.value = false;
                return Swal.fire({
                    title: "Incorrect price",
                    text: "you must put a price greater than 0",
                })
            }
            const datos = {
                ...stateData.value
            };
            axiosCT
                .categoryLabCreate(datos)
                .then(({ registro }) => {
                    loading.value = false;
                    resetuserData();
                    emit("createLaboratoryCategory", { status: true, registro });
                })
                .catch(() => {
                    loading.value = false;
                    emit("createLaboratoryCategory", { status: false });
                });
        };
        return {
            loading,
            stateData,
            optionsStatus,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            searchLabs
        };
    },
};
</script>

<style></style>
